@import 'src/assets/scss/functions';

.tri-tag {
  position: absolute;
  top: -.8rem;
  left: 3rem;
  border-left: 1rem solid transparent;
  border-right: 1rem solid transparent;
  border-bottom: .8rem solid var(--light-color);
}

.alg-link {
  color: var(--alg-secondary-color);
  text-decoration: none;

  &:not(.disabled) {
    cursor: pointer;
    color: var(--alg-primary-color);
  }

  &.disabled {
    cursor: auto;
    pointer-events: none;

    &:hover {
      color: initial;
    }
  }

  &.dots {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.flex-1, .alg-flex-1 {
  flex: 1;
}

.uppercase {
  text-transform: uppercase;
}

.cursor-pointer {
  cursor: pointer;
}

.text-center {
  text-align: center !important;
}

.text-dots {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.text-black {
  color: var(--alg-light-text-color);
}

.font-bold {
  font-weight: 700;
}

.alg-error-text {
  color: var(--base-color);
  font-size: 1.6rem;
  text-align: center;
}

.alg-base-text-color {
  color: var(--dark-text-color);
}

.alg-text-secondary-color {
  color: var(--alg-secondary-color);
}

.alg-warning-message {
  color: var(--warning-color);
}

.alg-validation-error {
  color: var(--danger-color);
}

.alg-full-centered-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.alg-opacity-0 {
  opacity: 0 !important;
}

.alg-base-title-primary-space {
  margin: toRem(6) 0 toRem(16) 0;
}

.alg-base-title-space {
  margin: toRem(38) 0 toRem(16) 0;
}

.alg-button-margin-right {
  margin-right: toRem(8);
}

.alg-no-top-margin {
  margin-top: 0;
}

.alg-no-bottom-margin {
  margin-bottom: 0;
}

.alg-disabled {
  pointer-events: none;
  opacity: 0.5;
}
